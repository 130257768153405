import React, { createContext, useState } from "react";
import { Swiper } from "antd-mobile";

import ControlComponent from "../ControlPage";
import "./index.css";
import Calculator from "../Calculator";

export const AppPlayerContext = createContext(null);
const components = [<ControlComponent />, <Calculator />];

const items = components.map((c, index) => (
  <Swiper.Item key={index}>{c}</Swiper.Item>
));

const MainComponent = () => {
  const [players, setPlayers] = useState([]);
  const [size, setSize] = useState(0);
  const [round, setRound] = useState(1);
  const [cardForms, setCardForms] = useState([]);

  return (
    <>
      <AppPlayerContext.Provider
        value={{
          players,
          setPlayers,
          size,
          setSize,
          round,
          setRound,
          cardForms,
          setCardForms,
        }}
      >
        <Swiper>{items}</Swiper>
      </AppPlayerContext.Provider>
    </>
  );
};

export default MainComponent;
