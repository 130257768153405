import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Button,
  Space,
  Stepper,
  Radio,
  Grid,
  CapsuleTabs,
  Form,
} from "antd-mobile";
import { GridItem } from "antd-mobile/es/components/grid/grid";
import { UserOutline, HandPayCircleOutline } from "antd-mobile-icons";
import { AppPlayerContext } from "../Main";
import "./index.css";
import HistoryModal from "../HistoryModal";

const PlayerCard = ({ player }) => {
  const { players, setPlayers, cardForms, setCardForms } =
    useContext(AppPlayerContext);

  const [visible, setVisible] = useState(false);

  const thisData = {
    character: "player",
    bet: 0,
    odds: 1,
    winlose: "1",
  };

  const [form] = Form.useForm();
  useEffect(() => {
    cardForms.push(form);
    setCardForms(cardForms);
  }, []);

  const updatePlayers = () => {
    let idx = players.findIndex((p) => {
      return p.id === player.id && p.name === player.name;
    });
    let old = players[idx];
    players[idx] = {
      id: old.id,
      name: old.name,
      wallet: old.wallet,
      lastRound: old.lastRound,
      historyList: old.historyList,
      ...thisData,
    };
    setPlayers(players);
  };

  const betOnchange = (e) => {
    thisData.bet = e;
    updatePlayers();
  };
  const oddsOnchange = (e) => {
    thisData.odds = e;
    updatePlayers();
  };
  const characterOnchange = (e) => {
    thisData.character = e;
    updatePlayers();
  };
  const winLostOnchange = (e) => {
    thisData.winlose = e;
    updatePlayers();
  };

  const showMore = () => {
    setVisible(true);
  };

  return (
    <div className="player-card">
      <Card
        title={
          <Grid columns={3} gap={18}>
            <GridItem>
              <UserOutline /> {player.name ? player.name : "UNKNOWN"}
            </GridItem>
            <GridItem>
              <HandPayCircleOutline />
              {player.wallet ? player.wallet : 0}
            </GridItem>
          </Grid>
        }
      >
        <div className="palyer-card-content">
          <Form form={form}>
            <Form.Item name="bet">
              <CapsuleTabs onChange={characterOnchange}>
                <CapsuleTabs.Tab
                  title="Player"
                  key="player"
                  destroyOnClose="true"
                >
                  <Grid columns={4} gap={2}>
                    <Grid.Item>Bet</Grid.Item>
                    <GridItem>
                      <Stepper
                        min={0}
                        defaultValue={0}
                        onChange={betOnchange}
                      />
                    </GridItem>
                  </Grid>
                  <Grid columns={4} gap={2}>
                    <Grid.Item>Odds</Grid.Item>
                    <GridItem>
                      <Stepper
                        min={1}
                        defaultValue={1}
                        onChange={oddsOnchange}
                      />
                    </GridItem>
                  </Grid>

                  <Radio.Group onChange={winLostOnchange} defaultValue="1">
                    <Space direction="vertical">
                      <Radio value="1">Win</Radio>
                      <Radio value="2">Lose</Radio>
                    </Space>
                  </Radio.Group>
                </CapsuleTabs.Tab>
                <CapsuleTabs.Tab title="Banker" key="banker"></CapsuleTabs.Tab>
              </CapsuleTabs>
            </Form.Item>
          </Form>
        </div>

        <div className="palyer-card-foot">
          <Button shape="rounded" color="primary" onClick={showMore}>
            Show More
          </Button>
        </div>
        <HistoryModal
          visible={visible}
          setVisible={setVisible}
          player={player}
        />
      </Card>
    </div>
  );
};

export default PlayerCard;
