import React, { useEffect, useContext } from "react";
import { Form, Input, Button, Card, Grid } from "antd-mobile";
import { UserAddOutline, EditSOutline } from "antd-mobile-icons";
import {
  clearPlayers,
  createPlayer,
  getPlayers,
} from "../../api/player_api.js";
import { AppPlayerContext } from "../Main/index.jsx";
import "./index.css";

const ControlComponent = () => {
  const { players, setPlayers, size, setSize, setRound } =
    useContext(AppPlayerContext);

  const [form] = Form.useForm();

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    let player = {
      name: values.username,
      character: "player",
      bet: 0,
      odds: 1,
      winlose: "1",
      wallet: 0,
      lastRound: 0,
      historyList: [],
    };
    createPlayer(player).then((rsp) => {
      if (rsp.data) {
        form.resetFields();
        let playerlist = players ? players : [];

        playerlist.push(rsp.data);
        setPlayers(playerlist);
        setSize(playerlist.length);
      }
    });
  };

  const handleClear = () => {
    clearPlayers().then((rsp) => {
      if (rsp.status === 200) {
        setPlayers([]);
        setSize(0);
        setRound(1);
      }
    });
  };

  useEffect(() => {
    getPlayers().then((rsp) => {
      if (rsp.data) {
        setPlayers(rsp.data);
        if (rsp.data[0]) {
          let lastRound = rsp.data[0].lastRound ? rsp.data[0].lastRound : 0;
          if (lastRound) {
            setRound(lastRound + 1);
          }
        }
      }
    });
  }, [size]);

  //   useEffect(() => {
  //     getPlayers().then((rsp) => {
  //       if (rsp.data) {
  //         setPlayers(rsp.data);
  //       }
  //     });
  //   }, [round]);

  return (
    <div class="input-form">
      <Grid columns={3} gap={8}>
        <Grid.Item span={3}>
          <Card
            title={
              <div>
                <UserAddOutline
                  style={{ marginRight: "4px", color: "#1677ff" }}
                />
                Add New User
              </div>
            }
          >
            <Form
              form={form}
              layout="horizontal"
              mode="card"
              footer={
                <div className="form-footer">
                  <Grid columns={2} gap={8}>
                    <Grid.Item>
                      <Button
                        onClick={handleSubmit}
                        block
                        type="submit"
                        color="primary"
                        size="large"
                        shape="rounded"
                      >
                        Add
                      </Button>
                    </Grid.Item>
                    <Grid.Item>
                      <Button
                        onClick={handleClear}
                        block
                        type="submit"
                        color="danger"
                        size="large"
                        shape="rounded"
                      >
                        Clear
                      </Button>
                    </Grid.Item>
                  </Grid>
                </div>
              }
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Username cannot be empty" },
                ]}
                label={
                  <div>
                    <EditSOutline
                      style={{ marginRight: "4px", color: "#1677ff" }}
                    />
                    Username
                  </div>
                }
              >
                <Input clearable placeholder="Please input usernmae" />
              </Form.Item>
            </Form>
          </Card>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default ControlComponent;
