import api from "../api";

const createPlayer = (player) => {
  return api.post(`/gambling`, player);
};

const getPlayers = () => {
  return api.get(`/gambling`);
};

const clearPlayers = () => {
  return api.delete(`/gambling`);
};

const updatePlayers = (players) => {
  return api.put(`/gambling`, players);
};

export { createPlayer, getPlayers, clearPlayers, updatePlayers };
