import { Button, Grid } from "antd-mobile";
import { useContext } from "react";
import BankerAndPlayer from "../BankerAndPlayer";
import { AppPlayerContext } from "../Main";
import { updatePlayers } from "../../api/player_api.js";
import "./index.css";

const Calculator = () => {
  const { players, setPlayers, round, setRound, cardForms } =
    useContext(AppPlayerContext);

  const calculate = () => {
    let sum = 0;

    //update player
    let updatedPlayers = players.map((player) => {
      let { character, bet, odds, wallet, winlose } = {
        ...player,
      };
      if (character === "player") {
        let money = bet * odds;
        let result = winlose === "1" ? money : money * -1;
        player.wallet = wallet + result;
        player.lastRound = round;
        sum += result;

        let record = {
          round: round,
          winlose: winlose,
          bet: bet,
          odds: odds,
          character: character,
          result: result,
        };

        player.historyList.push(record);
      }

      return player;
    });

    //update banker
    updatedPlayers = updatedPlayers.map((player) => {
      let { character } = { ...player };
      if (character === "banker") {
        let isWin = sum > 0 ? "2" : "1";
        let result = isWin === "1" ? sum : sum * -1;
        player.winlose = isWin;
        player.wallet += result;
        player.lastRound = round;
        let record = {
          round: round,
          winlose: isWin,
          bet: 0,
          odds: 1,
          character: character,
          result: result,
        };

        player.historyList.push(record);
      }

      return player;
    });

    cardForms.forEach((f) => {
      f.resetFields();
    });

    updatePlayers(updatedPlayers).then((rsp) => {
      if (rsp.data) {
        setPlayers(rsp.data);
      }
    });

    let newRound = round + 1;
    setRound(newRound);
  };

  //   const nextRound = () => {
  //     let newRound = round + 1;
  //     setRound(newRound);
  //   };
  return (
    <>
      <h3>Round {round}</h3>
      <BankerAndPlayer />
      <Grid columns={3} gap={8}>
        <Grid.Item>
          <Button
            shape="rounded"
            color="warning"
            className="calculate-button"
            onClick={calculate}
          >
            Calculate
          </Button>
        </Grid.Item>
        {/* <Grid.Item>
          <Button
            shape="rounded"
            color="primary"
            className="calculate-button"
            onClick={nextRound}
          >
            Next Roud
          </Button>
        </Grid.Item> */}
      </Grid>
    </>
  );
};

export default Calculator;
