import { Button } from "antd-mobile";
import { Modal } from "antd";
import PlayerHistory from "../PlayerHistory";

const HistoryModal = ({ visible, setVisible, player }) => {
  const handleOk = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={400}
      height={600}
      title="History"
      open={visible}
      closable={false}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={handleOk}
          className="modalOkButton"
        >
          OK
        </Button>,
      ]}
    >
      <PlayerHistory player={player} />
    </Modal>
  );
};

export default HistoryModal;
