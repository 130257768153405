import "./index.css";
import { Footer } from "antd-mobile";

import PageContainer from "../page_container";

const AppLayout = ({ children }) => (
  <div className="app-layout">
    <PageContainer>{children}</PageContainer>
    <Footer
      content="Gambling Caulator@F Lam All rights reserved"
      className="app-footer"
    ></Footer>
  </div>
);
export default AppLayout;
