import { useEffect, useContext } from "react";
import PlayerCard from "../PlayerCard";
import { getPlayers } from "../../api/player_api.js";
import { AppPlayerContext } from "../Main/index.jsx";

const BankerAndPlayer = () => {
  const { players, setPlayers, size } = useContext(AppPlayerContext);

  useEffect(() => {
    getPlayers().then((rsp) => {
      if (rsp.data) {
        setPlayers(rsp.data);
      }
    });
  }, [size]);

  return players.map((player) => (
    <PlayerCard player={player} key={player.id} />
  ));
};

export default BankerAndPlayer;
