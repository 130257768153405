const Recrods = ({ rec }) => {
  return (
    <>
      <div>
        round: {rec.round}, {rec.character}, bet: {rec.bet}, odds:
        {rec.odds}, {rec.winlose === "1" ? "win" : "lose"} {rec.result}
      </div>
    </>
  );
};

export default Recrods;
